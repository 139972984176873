<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{$t('elearning_iabm.course_document')}}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="mobile"
                      >
                        <template v-slot:label>
                          {{$t('globalTrans.mobile')}}
                        </template>
                        <b-form-input
                            id="mobile"
                            v-model="search.mobile"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="date_from"
                      >
                        <template v-slot:label>
                          {{$t('elearning_iabm.date_from')}}
                        </template>
                        <b-form-input
                            class="fromDate"
                            v-model="search.date_from"
                            :placeholder="$t('globalTrans.select_date')"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="date_to"
                      >
                        <template v-slot:label>
                          {{$t('elearning_iabm.date_to')}}
                        </template>
                        <b-form-input
                            class="fromDate"
                            v-model="search.date_to"
                            :placeholder="$t('globalTrans.select_date')"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <b-button type="button" variant="primary float-right" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('elearning_iabm.course_document_list')}}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(mobile)="data">
                                            {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(data.item.mobile, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(name)="data">
                                            {{ $i18n.locale === 'bn' ? data.item.pname_bn : data.item.pname }}
                                        </template>
                                        <template v-slot:cell(designation)="data">
                                            <span v-if="data.item.designation_id && data.item.not_here_designation == 0">
                                            {{ getDesignation(data.item.designation_id) }}
                                            </span>
                                            <span v-else>
                                            {{ ($i18n.locale=='bn') ? data.item.designation_bn : data.item.designation_en}}
                                            </span>
                                        </template>
                                        <!-- <template v-slot:cell(organization)="data">
                                            <span v-if="data.item.org_id">
                                            {{ getOrgName(data.item.org_id) }}
                                            </span>
                                            <span v-else>
                                            {{ ($i18n.locale=='bn') ? data.item.org_name_bn : data.item.org_name_en}}
                                            </span>
                                        </template> -->
                                        <template v-slot:cell(office)="data">
                                        <span v-if="data.item.profession_type == 1 && data.item.not_here_office == 0">{{ getOfficeName(data.item.professional_office_id) + ' , ' + getOrgName(data.item.professional_org_id) }}</span>
                                        <span v-if="data.item.profession_type == 1 && data.item.not_here_office == 1">{{ ($i18n.locale=='bn') ? data.item.office_name_bn : data.item.office_name + ', ' + getOrgName(data.item.professional_org_id) }}</span>
                                        <span v-if="data.item.profession_type != 1">{{ ($i18n.locale=='bn') ? data.item.professional_other_org_name_bn : data.item.professional_other_org_name }}</span>
                                        </template>
                                        <!-- <template v-slot:cell(email)="data">
                                            {{ data.item.email }}
                                        </template> -->
                                        <template v-slot:cell(type_name)="data">
                                        <span class="capitalize">{{ data.item.type_name }}</span>
                                        </template>
                                        <template v-slot:cell(date)="data">
                                          <span class="capitalize">{{ data.item.date | dateFormat }}</span>
                                        </template>
                                        <template v-slot:cell(course_document)="data">
                                          <a target = '_blank' v-if="data.item.course_document" :href="baseUrl + 'download-attachment?file=uploads/iabm-documents/' + data.item.course_document" title="Course Document" class="btn_table_action table_action_status" download><i class="ri-download-cloud-fill"></i></a>
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                            <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <a href="javascript:" class="btn_table_action table_action_edit" title="Course Document Update" v-b-modal.modal-4 @click="edit(data.item)"><i class="ri-ball-pen-fill"></i></a>
                                            <a href="javascript:" class="btn_table_action table_action_status" title="Change Status" v-if="data.item.status === 1" @click="remove(data.item)"><i class="fas fa-toggle-on"></i></a>
                                            <a href="javascript:" class="btn_table_action table_action_toggle" title="Change Status" v-if="data.item.status === 2" @click="remove(data.item)"><i class="fa fa-toggle-off"></i></a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>

    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Form from './Form'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { requestDocumentList, requestDocumentToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import flatpickr from 'flatpickr'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form
    },
    data () {
        return {
            search: {
                org_id: 0,
                mobile: '',
                date_from: '',
                date_to: ''
            },
            baseUrl: trainingElearningServiceBaseUrl
        }
    },
    computed: {
        formTitle () {
            return (this.editItemId === 0) ? this.$t('elearning_iabm.request_coursee_document') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_iabm.request_coursee_document') + ' ' + this.$t('globalTrans.update')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                { label: this.$t('globalTrans.name'), class: 'text-center' },
                { label: this.$t('globalTrans.designation'), class: 'text-center' },
                // { label: this.$t('globalTrans.organization'), class: 'text-center' },
                { label: this.$t('elearning_tpm.work_place'), class: 'text-center' },
                { label: this.$t('globalTrans.mobile'), class: 'text-center' },
                // { label: this.$t('globalTrans.email'), class: 'text-center' },
                { label: this.$t('globalTrans.date'), class: 'text-center' },
                { label: this.$t('elearning_iabm.course_document'), class: 'text-center' },
                { label: this.$t('globalTrans.reason'), class: 'text-center' },
                { label: this.$t('globalTrans.status'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center w-10' }
            ]

            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'name' },
                { key: 'designation' },
                // { key: 'organization' },
                { key: 'office' },
                { key: 'mobile' },
                // { key: 'email' },
                { key: 'date' },
                { key: 'course_document' },
                { key: 'reason_bn' },
                { key: 'status' },
                { key: 'action' }
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'name' },
                { key: 'designation' },
                // { key: 'organization' },
                { key: 'office' },
                { key: 'mobile' },
                // { key: 'email' },
                { key: 'date' },
                { key: 'course_document' },
                { key: 'reason' },
                { key: 'status' },
                { key: 'action' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    async created () {
        this.search = Object.assign({}, this.search, {
            org_id: this.$store.state.dataFilters.orgId
        })
        this.loadData()
    },
    mounted () {
        core.index()
        flatpickr('.fromDate', {})
    },
    methods: {
        getDesignation (id) {
            const designation = this.$store.state.commonObj.designationList.find(item => item.value === id)
            if (this.$i18n.locale === 'bn') {
                return designation !== undefined ? designation.text_bn : ''
            } else {
                return designation !== undefined ? designation.text_en : ''
            }
        },
        getOfficeName (id) {
            const office = this.$store.state.commonObj.officeList.find(item => item.value === id)
            if (this.$i18n.locale === 'bn') {
                return office !== undefined ? office.text_bn : ''
            } else {
                return office !== undefined ? office.text_en : ''
            }
        },
        getOrgName (id) {
            const trainingType = this.$store.state.commonObj.organizationProfileList.find(item => item.value === id)
            if (this.$i18n.locale === 'bn') {
                return trainingType !== undefined ? trainingType.abbreviation_bn : ''
            } else {
                return trainingType !== undefined ? trainingType.abbreviation : ''
            }
        },
        dataChange () {
            this.loadData()
        },
        async searchData () {
            this.loadData()
        },
        remove (item) {
            this.changeStatus(trainingElearningServiceBaseUrl, requestDocumentToggleStatus, item, 'training_e_learning', 'trainerEvaluationList')
        },
        loadData () {
            const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(trainingElearningServiceBaseUrl, requestDocumentList, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', response.data.data)
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        }
    }
}
</script>
